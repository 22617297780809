.category_switch_blog {
  a {
    color: #555;
    margin-left: 15px;
    &.active {
      color: #23a84c;
    }
  }
}
.category_box {
  margin-bottom: 20px;
  background-color: white;
  transition: 0.3s;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  h3 {
    font-size: 22px;
    font-weight: bold;
    color: #555;
    font-weight: 400;
  }

  ul {
    padding: 0;
    li {
      list-style: none;
      border-bottom: 1px solid #ccc;
      &:last-child {
        border-bottom: none;
      }
      .i {
        position: relative;
        display: flex;
        font-family: "Material-Design-Iconic-Font";
        color: #23a84c;
        font-size: 14px;
        padding-right: 5px;
      }
      i {
        color: #23a84c;
      }
      a {
        font-size: 14px;
        color: #555;
        padding: 5px 0;
        display: inline-block;
        margin-left: 5px;
        &:hover {
          text-decoration: none;
          padding-left: 10px;
        }
        &.active {
          color: #23a84c;
          font-weight: bold;
        }
      }
    }
  }
}
