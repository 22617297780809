
.x_un_contet {
  min-height: 850px;
}

.unsub_container {
  width: 600px;
  margin: 0 auto;
  background: #fefefe;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 30px;
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  .unsubscri_svg {
    max-width: 350px;
    margin: 20px auto;
  }
  .title {
    font-size: 25px;
  }
}

.inner-container {
  width: 100%;
}

svg {
  max-width: 90%;
  position: relative;
  left: 5%;
  margin: 0 auto;
}

.bottom {
  text-align: center;
  max-width: 70%;
  position: relative;
  margin: 0 auto;
  h2 {
    font-family: "Rokkitt", sans-serif;
    letter-spacing: 0.05em;
    font-size: 30px;
    line-height: 1.2;
    text-align: center;
    margin: 0 auto 0.25em;
  }
  p {
    color: #777;
    letter-spacing: 0.1em;
    font-size: 16px;
    line-height: 1.4;
    margin: 0 auto 2em;
  }
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  button {
    padding: 10px 30px;
    font-size: 20px;
    background-color: #41CB8C;
    border: 0;
    cursor: pointer;
    border-radius: 4px;
    letter-spacing: 0.1em;
    color: #ffffff;
    margin-right: 20px;
    margin-bottom: 15px;
    transition: all 0.25s ease-in-out;
    &:hover {
      background-color: darken(#41CB8C, 10%);
    }
    &#cancel {
      margin-right: 0;
      color: #333;
      background-color: #c6f2dd;
      &:hover {
        background-color: darken(#c6f2dd, 10%);
      }
    }
    &#go-back {
      display: none;
    }
    &:focus {
      border: none;
      outline: 0;
    }
  }
}

#blob-3,
#blob-2,
#mouth-happy,
#mouth-sad,
#eyebrow-happy-left,
#eyebrow-happy-right,
#eyes-laughing,
#open-mouth,
#tongue,
#mouth-scared {
  display: none;
}

.happy {
  #mouth-happy,
  #eyebrow-happy-left,
  #eyebrow-happy-right {
    display: block;
  }
  #mouth-worry, #eyebrow-sad-left, #eyebrow-sad-right {
    display: none;
  }
}

.sad {
  #mouth-happy,
  #eyebrow-happy-left,
  #eyebrow-happy-right {
    display: none;
  }
}

.cry {
  #mouth-sad {
    display: block;
  }
  #mouth-worry,
  #mouth-happy,
  #eyebrow-happy-left,
  #eyebrow-happy-right {
    display: none;
  }
}

@media (max-width: 699px) {
  .unsub_container {
    width: 90%;
    top: 70%;
  }
  .bottom {
    margin-top: 1em;
    max-width: 90%;
  }
}

@media (max-width: 399px) {
  .unsub_container {
    padding: 20px;
  }
  .bottom {
    h2 {
      font-size: 24px;
    }
  }
  .buttons {
    flex-direction: column;
    button {
      margin-right: 0;
    }
  }
  svg {
    padding-top: 0;
  }
}

.shaker_scg {
  animation: shake_2 0.82s cubic-bezier(.36, .07, .19, .97) both;
}

.jumper_svg {
  animation: jump 0.82s cubic-bezier(.36, .07, .19, .97) both;
}

@keyframes shake_2 {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@-webkit-keyframes jump {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}