body {
  font-family: $appFont;
}

//html {
//  scroll-behavior: smooth;
//}

.container-fluid {
  padding-left: 50px;
  padding-right: 50px;
}

.pull-right {
  float: right;
}

.modal-error {
  .ant-modal-body {
    border-top: 6px solid $colorDanger;
  }
}

.no-padding {
  padding: 0;
}

.section-header {
  min-height: 50px;
  background-color: white;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.08);
}

.nav.navbar-nav {
  li {
    display: inline !important;
    background-color: red;
    a {
      display: inline;
    }
  }
}

.tr-banner {
  padding: 35px 0 !important;
  .banner-content {
    h1 {
      font-size: 35px;
    }
    h2 {
      font-size: 20px;
    }
  }
}

.tr-banner,
.tr-steps {
  h1,
  h2,
  h3,
  h4 {
    color: white;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 700px;
  }
}

.sticky_footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px;
  box-shadow: 1px -1px 5px rgba(0, 0, 0, 0.1);
  min-height: 50px;
  background-color: #fefefe;
  z-index: 999;
  .btn.btn-primary {
    padding: 10px 20px;
  }
}

.v-error {
  color: red;
  font-size: 14px;
  .anticon {
    vertical-align: 0;
  }
}

.social_login_option {
  display: inline;
}

@media all and (max-width: 430px) {
  .social_login_option {
    display: block;
  }
}

.uploader-modal {
  position: relative;
  .rotator_crop {
    position: absolute;
    font-size: 22px;
    padding: 5px 15px 15px 15px;
    font-weight: bold;
    color: $colorPrimary;
    cursor: pointer;
  }
  &.rotate_90 {
    img {
      transform: rotate(90deg);
    }
  }
  &.rotate_180 {
    img {
      transform: rotate(180deg);
    }
  }
  &.rotate_270 {
    img {
      transform: rotate(270deg);
    }
  }
}

.category_drawer {
  .nav-closer {
    position: absolute;
    top: 18px;
    i {
      color: #999;
      font-size: 20px;
    }
    right: 20px;
  }
  .ant-drawer-content-wrapper {
    width: 50% !important;
  }
  .ant-drawer-wrapper-body {
    position: relative;
  }
  .ant-drawer-title {
    color: #777;
    font-size: 20px;
    font-weight: bold;
  }
}

@media all and (max-width: 580px) {
  .category_drawer {
    .ant-drawer-content-wrapper {
      width: 80% !important;
    }
  }
}

.category_mobile_view {
  position: fixed;
  top: 165px;
  left: 0;
  width: 100%;
  background-color: red;
  height: 100%;
  z-index: 99;
  overflow: scroll;
}

.mobile_nav {
  ul {
    li a {
      &.active {
        color: $colorPrimary !important;
        i {
          color: $colorPrimary !important;
        }
      }
      i {
        font-size: 20px;
        color: #777;
        text-align: center;
        font-weight: bold;
      }
      display: flex;
      color: #888 !important;
      flex-direction: column;
      line-height: 25px !important;
      font-size: 10px !important;
      justify-content: center;
      padding-bottom: 0 !important;
    }
  }
}

.user_profile_nav {
  display: flex;
  padding: 0 !important;
  align-items: center;
  img {
    margin-top: -5px;
  }
}

.main-page-content {
  padding-top: 80px;
}

@media all and (max-width: 768px) and (min-width: 424px) {
  .main-page-content {
    padding-top: 140px;
  }
}

@media all and (max-width: 425px) {
  .main-page-content {
    padding-top: 0;
  }
}

.app-header {
  background-color: white;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
  border-top: 20px solid rgba(0, 0, 0, 0.04);
  .main-navigation {
    &.huge_device_navigation {
    }
    .small-device-nav-item {
      width: 100%;
      background-color: white;
      z-index: 9999;
      transition: 0.3s;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.3);
    }
    width: auto;
    display: flex;
    background-color: white;
    z-index: 9999;
    .logo-container {
      padding: 5px 0;
      flex: 2;
      img {
        width: 100%;
      }
    }
    .nav-items {
      flex: 6;
      //display: flex;
      ul {
        float: left;
        li {
          display: inline;
          a {
            line-height: 70px;
            font-size: 13px;
            color: #333;

            &.active {
              color: $colorPrimary;
              font-weight: bold;
            }
            //background-color: red;
            padding: 25px 15px;
            &:hover {
              color: #00b365 !important;
              i {
                color: #00b365;
              }
            }
          }
        }
      }
      .auth-nav {
        float: right;
        &.extra-option {
          margin-top: 14px;
          a {
            i {
              display: inline-block;
              background-color: #eee;
              font-size: 12px !important;
              padding: 5px;
              border-radius: 20px;
            }
          }
        }
        a {
          padding: 25px 10px;
          i {
            color: #666;
          }
        }
      }
    }
  }

  .small-device-navigation {
    .nav-items {
      ul {
        display: none;
        width: 100%;
        //flex-direction: column;
        justify-content: space-between;
        align-content: space-between;
        li {
        }
      }
    }
  }
}

@media all and (min-width: 767px) {
  .app-header {
    position: fixed;
    z-index: 1000;
    width: 100%;
  }
}

.user_profile_image_nav {
  height: 32px;
  line-height: 1;
  border-radius: 50px;
  margin-top: 10px;
  border: 3px solid #eee;
  width: 32px;
  display: inline-block;
  //margin-top: 10px;
}

.user_profile_nav {
  display: flex;
  padding: 0 !important;
  align-items: center;
  img {
    margin-top: -5px;
  }
}

.mobile_nav ul li a i {
  font-size: 20px;
  color: #777;
  text-align: center;
  font-weight: bold;
}

@media all and (min-width: 767px) {
  .app-header {
    position: fixed;
    z-index: 1000;
    width: 100%;
  }
}

li.toggle {
  display: none !important;
  cursor: pointer;
  float: right;
  text-align: right;
  padding-right: 20px;
}

@media all and (max-width: 992px) {
  .logo-container {
    flex: 3 !important;
  }
  .big-device-navigation {
    display: none !important;
  }
  .small-device-navigation {
    ul {
      display: flex !important;
    }
  }
}

@media all and (max-width: 767px) {
  .logo-container {
    flex: 4 !important;
    margin-top: 10px;
  }
}

@media all and (max-width: 505px) {
  li.toggle {
    display: block !important;
  }
  .small-device-navigation {
    //display: block!important;
    .nav-items {
      ul {
        flex-direction: row !important;
        overflow: hidden;
        &.hide {
          height: 25px;
        }
        &.show {
          height: auto;
        }
        li {
          a {
            padding-bottom: 0 !important;
            line-height: 25px !important;
          }
        }
      }
    }
  }
}

.breadcrumb-social.small li {
  margin-right: 5px !important;
}

@media all and (max-width: 550px) {
  .job_list_view {
    .list_view {
      display: block !important;
    }
    flex-wrap: wrap !important;
    .job_action {
      padding-top: 0 !important;
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-between;
      span {
        margin-bottom: 0 !important;
      }
    }
  }

  .job_list_view {
    .list_view {
      .job_logo {
        max-width: 100%;
        margin-top: 15px;
      }
      //.employee_img {
      //  max-width: 100%;
      //}
    }
  }
}

.greet-modal-content {
  text-align: center;
  h2 {
    color: $colorPrimaryActive;
    font-size: 14px;
    font-weight: bold;
  }
  h1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    margin: 10px auto 0 auto;
    color: #313846 !important;
    opacity: 0.9;
    width: 100%;
  }
  p {
    color: #8c9097;
    width: 90%;
    font-size: 12px;
    margin: 10px auto;
  }
  .reg_type {
    margin: 30px 0 20px 0;
    h4 {
      font-size: 16px;
      color: #233a2f;
      margin-bottom: 0;
      + p {
        font-size: 10px;
        color: #999;
        font-weight: bold;
        margin-top: 0;
      }
    }
    .steps {
      text-align: center;
      margin-bottom: 15px;
      img {
        height: 70px;
        width: 70px;
        margin: auto;
        border-radius: 50%;
        border: 3px solid #eee;
      }
      h4 {
        font-size: 12px;
        margin-top: 5px;
        font-weight: bold;
      }
    }
  }
  .reg_now_button {
    background-color: #dceae4;
    padding: 15px 20px;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 20px;
    border-radius: 15px;
    margin-top: -20px;
    //background-color: ;
  }
}

@media (max-width: 767px) {
  .breadcrumb-social li {
    margin-right: 5px !important;
  }
}

.ant-notification {
  z-index: 999999;
}

.title-after {
  display: flex;
  justify-content: space-between;
}

.category_sm_d {
  display: none !important;
}

.user_profile_image_nav {
  height: 32px;
  line-height: 1;
  border-radius: 50px;
  margin-top: 10px;
  border: 3px solid #eee;
  width: 32px;
  display: inline-block;
  //margin-top: 10px;
}

.drawer_nav {
  .nav {
    display: block !important;
  }
}

@media all and (max-width: 768px) {
  .hidden-sm {
    display: none;
  }

  .app-header .main-navigation .nav-items ul li a {
    line-height: 30px;
    vertical-align: middle !important;
  }

  .big-on-sm {
    display: block;
    width: 100%;
  }

  .account_type_modal {
    max-width: 300px !important;
  }
  .cat_job_section .category_lister {
    display: none;
  }
  .category_sm_d {
    display: inline !important;
  }
}

.account_type_modal {
  width: auto !important;
  max-width: 550px !important;
  border-top: 7px solid $colorPrimary;
  .ant-modal-content {
    border-radius: 3px !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .ant-modal-body {
    padding: 15px 0;
  }
  .ant-modal-header {
    display: none;
  }
  .ant-modal-footer {
    display: none;
  }

  .ant-modal-close {
    display: none;
  }
  &.renewal_date {
    .ant-modal-close {
      display: block;
    }
  }
  .back_arrow {
    position: absolute;
    left: 20px;
    top: 15px;
    cursor: pointer;
    small {
      font-size: 12px;
      //font-weight: bold;
    }
    i.anticon {
      vertical-align: -0.02em;
      font-size: 17px;
    }
  }
  .help_text {
    font-size: 12px;
    position: absolute;
    right: 20px;
    bottom: 10px;
    color: #888;
    i.anticon {
      vertical-align: 0.1em;
      font-size: 10px;
    }
  }
}

.acc_label {
  margin: auto;
  max-width: 370px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  &.wide {
    max-width: 390px;
  }
  p {
    color: #999;
    font-size: 12px;
  }
  p:first-child {
    color: #888;
    font-size: 15px;
    font-weight: bold;
  }
  //padding-bottom: 5px;
}

.acc_selector {
  //background-color: #fefefe;
  padding: 15px;
  max-width: 370px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  &.wide {
    max-width: 390px;
  }
  .select_box {
    background-color: rgba(2, 179, 100, 0.1);
    cursor: pointer;
    .anticon {
      font-size: 25px;
      color: $colorPrimary;
    }
    p {
      padding-top: 12px;
      font-size: 16px;
      color: #233a2f;
      opacity: 0.8;
      font-weight: bold;
    }
    small {
      font-size: 10px;
      color: #999;
      font-weight: bold;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 5px;
    height: auto;
    width: 160px;
    padding: 15px 10px 10px 10px;
    // border: 1px solid #eee;
    //box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  }
}

.cat_job_section {
  display: flex;
  .category_lister {
    position: relative;
    transition: 0.3s;
    &.hide {
      margin-left: -310px;
    }
    .cat_settings {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
      z-index: 1;
      i {
        font-size: 18px;
      }
    }
    //flex: 3;
    width: 360px;
    .categories_list {
      padding: 30px 10px 0 30px;
      position: relative;

      .category_switcher {
        display: flex;
        padding-bottom: 10px;
        a {
          color: #999;
          flex: 0.37;
          margin-right: 15px;
          &.active {
            color: #555;
            border-bottom: 2px solid $colorPrimary;
          }
        }
      }

      ul {
        padding: 10px 20px 0 0;
        li {
          //background-color: red;
          width: 100%;
          div.category-items {
            display: flex;
            .anticon {
              vertical-align: 0.2em;
              flex: 0.3;
              align-self: center;
              padding-right: 8px;
            }
            img {
              width: 20px;
              padding-right: 5px;
            }
            a {
              flex: 6;
              color: #555;
              &:hover {
                color: $colorPrimary;
              }
            }
            a.expander {
              flex: 0.8;
              display: block;
              font-weight: bold;
              //align-self: flex-end;
              //justify-content: flex-end;
              margin-left: 20px;
              &.open {
                transform: rotate(45deg);
              }
            }
          }

          ul.child-categories {
            padding-left: 20px !important;
            li {
              margin-bottom: 10px;
              font-size: 13px;
              a {
                color: #777;
                display: flex;
                span {
                  padding-right: 5px;
                }
              }
            }
            display: none;
            &.open {
              display: block;
            }
          }

          font-size: 14px;
          margin-bottom: 20px;
        }
      }
    }
    h4 {
      font-size: 13px;
      //font-weight: bold;
      color: #888;
      margin-bottom: 20px;
    }
  }
  .hire_job_section {
    flex: 9;
    background-color: white;
    min-height: 200px;
  }
}

.large_screen_search {
  display: block;
}

.navigation_tob_bar_toggle {
  height: 0;
  overflow: hidden;
  transition: 0.3s;
}

@media all and (max-width: 768px) {
  .large_screen_search {
    display: none;
  }
  .banner-form {
    min-height: 135px;
    margin-top: 0 !important;
    input {
      padding: 0 20px !important;
    }
  }
}

.breadcrumb-social li a {
  z-index: 2 !important;
}

.app_modal_container {
  .ant-modal-header {
    display: none;
  }
  &.no_close {
    .ant-modal-close {
      display: none;
    }
  }

  .ant-modal-content {
    border-top: 7px solid $colorPrimary;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .ant-modal-footer {
    display: none;
  }
}

.app_modal_container {
  .banner-content {
    border: 1px solid #cdcdcd;
  }
}

@media all and (max-width: 768px) {
  .cat_job_section {
    margin-top: 0;
  }
}

.job-home-container {
  min-height: 200px;
  background-color: white;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.08);
}

.filter-tab {
  display: flex;
  justify-content: space-between;
  padding: 0;
  h2 {
    font-size: 20px;
    align-self: center;
    flex: 1.5;
    color: #555;
  }

  .tab-items {
    flex: 1;
    display: flex;
    align-items: stretch;
    height: 100%;
    //justify-content: space-between;
    a {
      margin-right: 15px;
      align-self: stretch;
      display: block;
      padding: 30px 15px 8px 0;
      color: #666;
      font-size: 14px;
      font-weight: bold;
      &.active {
        border-bottom: 4px solid $colorPrimary;
        color: $colorPrimary;
        .jobs-counter {
          background-color: $colorPrimary;
          color: white;
        }
      }
      .jobs-counter {
        background-color: #eee;
        color: #777;
        font-size: 10px;
        border-radius: 10px;
        padding: 2px 3px;
        display: inline-block;
        height: 15px;
        width: 25px;
        margin-left: 5px;
        text-align: center;
        font-weight: bold;
      }
    }
  }
  //div.search-filter {
  //  flex: 1.5;
  //  align-self: center;
  //  justify-content: flex-end;
  //  display: flex;
  //  .search-input {
  //    margin-right: 20px;
  //  }
  //}
}

.filter-tab-container {
  background-color: white;
  margin-bottom: 10px;
  display: flex;
  border-bottom: 1px solid #ccc;
  //box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  .search-result-display {
    min-height: 100px;
    background-color: white;
    margin-top: 20px;
  }
}

@media (min-width: 768px) {
  .dropdown:hover {
    background: #e7e7e7;
  }
  .dropdown:hover > .dropdown-menu {
    display: block;
  }
}

.interest-jobs-tab {
  margin-bottom: 50px;
}

.search-result-display {
  min-height: 100px;
  background-color: white;
  padding: 30px 0;
}

.grid-list-switcher {
  border-radius: 3px;
  border: 1px solid #eee;
  //width: 60px;
  overflow: hidden;
  display: flex;
  float: right;
  //justify-content: space-between;
  a {
    cursor: pointer;
    padding: 5px 8px;
    color: #999 !important;
    &.active {
      background-color: #eee;
    }
  }
}

header {
  background-color: #abde4b;
}

.navbar {
  background-color: #a7e359;
  border: #a7e359;
  border-radius: 0;
}

.navbar > .container-fluid ul li a,
.navbar > .container-fluid ul li.active a,
.navbar > .container-fluid .navbar-header a {
  color: #fff;
}

.navbar > .container-fluid ul li a:hover,
.navbar > .container-fluid ul li.active a:hover,
.navbar > .container-fluid .navbar-header a:hover {
  color: #fff;
  background-color: #acde49;
}

.navbar > .container-fluid li.active a {
  background-color: #8ed92b;
}

.btn-navbar {
  background-color: #26a65b;
  border: #26a65b;
  color: #fff;
}

.btn-navbar:hover {
  color: #fff;
  background-color: #8ed92b;
}

.navbar > .container-fluid .open a.dropdown-toggle,
.navbar > .container-fluid .open a.dropdown-toggle:visited,
.navbar > .container-fluid .open a.dropdown-toggle:link,
.navbar > .container-fluid .open a.dropdown-toggle:active {
  background-color: #8ed92b;
  color: #fff;
}

.dropdown-menu {
  background-color: #8ed92b;
  border: #8ed92b;
}

.navbar > .container-fluid .icon-bar {
  background-color: #8ed92b;
  border: #8ed92b;
}

.breadcrumb-logo {
  padding: 5px;
  //border-radius: 10px;
  background-color: white;
  //margin-bottom: 15px !important;
}

.page-title {
  h1 {
    margin-bottom: 0 !important;
  }
}

.action-text {
  a {
    color: #777;
  }
  .error {
    color: $colorDanger;
    &:hover {
      color: $colorDanger;
    }
    i {
      color: $colorDanger !important;
    }
  }
}

.error {
  color: $colorDanger;
  &:hover {
    color: $colorDanger;
  }
  i {
    color: $colorDanger !important;
  }
}

.job-grid-view {
  min-height: 100px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #eee;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
  margin-bottom: 30px;
  &.category-grid-view {
    min-height: 0;
    margin-bottom: 20px;
  }
  h2 {
    font-weight: bold;
    font-size: 16px;
    color: #555;
    margin-bottom: 2px;
  }
  h5 {
    margin-top: 8px;
    color: $colorPrimary;
    font-weight: bold;
    font-size: 14px;
  }
  a {
    i {
      font-size: 11px;
    }
    color: #999;
    font-size: 14px;
  }
  p {
    margin-top: 20px;
    font-size: 14px;
    color: #666;
  }

  .company_info {
    display: flex;
    .c_logo {
      flex: 1;
      padding: 0 5px 0 0;
    }
    .info {
      flex: 4;
    }
  }

  .grid-footer {
    border-top: 1px solid #eee;
    padding: 10px 0 0 0;
    margin-top: 20px;
    display: flex;
    align-items: stretch;
    &.category-display {
      padding-top: 0;
      border-top: none;
      margin-top: 0;
      display: flex;
      .expiry-days {
        flex: none;
      }
      align-content: space-between;
      justify-content: space-between;
    }
    span {
      border-radius: 5px;
      font-size: 13px;
      padding: 2px 5px;
      font-weight: bold;
      &.error {
        background-color: #ffeaec;
        color: #ef2b2b;
      }
      &.warning {
        background-color: #fff1de;
        color: #ffa000;
      }
      &.success {
        background-color: #f2f2f4;
        color: #8494aa;
      }
    }
    .expiry-days {
      flex: 2;
    }
    .user-action {
      float: right;
      a {
        color: #777;
        border: 1px solid #777;
        margin-left: 10px;
        display: inline-block;
        height: 25px;
        width: 25px;
        line-height: 23px;
        //padding: 0 5px;
        text-align: center;
        border-radius: 50px;
        &:hover,
        &.active {
          background-color: $colorPrimary;
          color: white;
          border-color: $colorPrimary;
        }
      }
    }
  }
}

.section_box {
  margin-top: 70px;
  h1.title_section {
    font-size: 40px;
    text-align: center;
    color: #666;
    margin-bottom: 20px;
  }
  .section-content-box {
    min-height: 150px;
    margin-bottom: 50px;
    margin-top: 30px;
  }
}

.user-profile-grid {
  div.about-user {
    display: flex;
  }

  padding: 10px;
  .user-img-grid {
    flex: 1;
    .img-holder {
      height: 70px;
      width: 70px;
      border-radius: 100px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
  .user-info {
    flex: 4;
    padding: 0 0 0 20px;
    h2 {
      //color: $colorPrimary;
      margin-bottom: 0;
      & + div.expertise_field {
        margin-top: 0;
        font-size: 12px;
        color: #888;
        margin-bottom: 15px;
      }
    }
  }
  .user-job-info {
    display: flex;
    .u-basic-info {
      margin-top: 20px;
      display: flex;
      flex: 1;
      &:nth-child(1) {
        flex: 1.6;
        margin-right: 10px;
      }
      .b-info {
        flex: 10;
        h3 {
          font-size: 13px;
          color: #777;
          line-height: 14px;
        }
        p {
          margin-top: 0;
          font-size: 12px;
          font-weight: bold;
        }
        &:last-child {
          margin-left: 10px;
        }
      }
    }
  }

  .grid-footer {
    display: flex;
    div.hire-me {
      flex: 1;
      padding-right: 5px;
    }
    div.view-profile {
      flex: 1;
      padding-left: 5px;
    }
  }
}

.auth-container {
  display: flex;
  flex-flow: column;
  flex: 1;
  padding: 80px 15px;

  .ant-dropdown-link {
    border-bottom: 2px solid $colorPrimary;
  }
  .custom_auth_msg {
    font-size: 14px !important;
    strong {
      font-weight: bold;
    }
    i {
      font-style: italic;
    }
  }
  .ant-input {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #9c9c9c;
    padding-left: 20px !important;
    &:focus {
      border-color: $colorPrimary;
      box-shadow: none;
    }
  }
  .ant-input-affix-wrapper .ant-input-prefix {
    left: 0;
  }
  .ant-input-prefix {
    i {
      color: $colorPrimary;
    }
  }
  .login-input-box {
    max-width: 630px;
    margin: 20px auto 0 auto;
    min-height: 150px;
    color: #333;
    input {
      background-color: transparent;
    }
    h1 {
      font-weight: 300;
      color: $colorPrimary;
      font-size: 40px;
      margin-bottom: 10px;
    }
    p {
      font-size: 13px;
      color: #555;
      line-height: 22px;
      margin-bottom: 30px;
      letter-spacing: 0.03em;
    }

    .login-extra-option {
      h3 {
        text-align: center;
        font-weight: 600;
        color: #777;
        line-height: 20px;
        margin-top: 30px;
      }
      p.alternate-login {
        text-align: center;
        font-size: 14px;
        margin-bottom: 20px;
        a {
          font-size: 16px;
          text-decoration: underline;
          &.facebook {
            color: #4266b2;
          }
          &.google {
            color: #ea4336;
          }
        }
      }

      .more-links {
        border-top: 1px solid #eee;
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        a {
          font-size: 15px;
          text-decoration: underline;
        }
      }
    }
  }
}

.my-resume {
  p {
    font-size: 14px;
    color: #666;
  }
  ul {
    &.tr-list {
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
    li {
      font-size: 14px;
    }
  }
}

.mock_warning {
  width: 100%;
  padding: 5px;
  border-radius: 0;
  color: red;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  text-align: center;
  i.anticon {
    margin-top: -10px;
    vertical-align: 0 !important;
  }
}

.resume-status {
  font-size: 10px;
  padding-left: 22px;
  display: block;
  line-height: 1px;
  padding-top: 2px;
  &.complete {
    color: #999;
  }
  &.incomplete {
    color: #999;
  }
  &.disabled {
    color: rgba(0, 0, 0, 0.25);
  }
  i {
    //margin-top: -5px;
    margin-right: 0 !important;
  }
}

.tab-contents-container {
  padding-top: 20px;
  .ant-select-selection__rendered {
    line-height: 45px;
  }
}

.resume-info label {
  color: #555 !important;
}

.additem {
  margin-bottom: 0 !important;
}

.company_incomplete {
  .ant-spin-text,
  .ant-spin-dot {
    color: red !important;
  }
}

.edit-resume {
  position: relative;
  .academics-info,
  .experiences-info {
    padding: 40px 15px 20px 15px;
    border-bottom: 1px solid #eee;
    //margin-bottom: 20px;
    &.even_box {
      background-color: #fcfcfc;
    }
  }
  .preview-job {
    position: absolute;
    right: 40px;
    z-index: 999;
  }
  .ant-input {
    background: #faf7fa;
    padding: 22px 15px;
    font-size: 14px;
    //margin-bottom: 15px;
  }
  .ant-input-number {
    background: #faf7fa;
    height: 45px;
    line-height: 50px;
  }
  .ant-calendar-picker {
    display: block;
  }
  .ant-calendar-picker-clear,
  .ant-calendar-picker-icon {
    top: 38%;
  }
  .ant-select {
    width: 100%;
  }
  .ant-select-selection {
    background: #faf7fa;
    height: 45px;
    //padding: 22px 15px;
    font-size: 14px;
    //margin-bottom: 15px;
    .ant-select-selection-selected-value {
      line-height: 45px;
    }
    .ant-select-dropdown-placement-bottomLeft {
      top: 200px !important;
    }
    &.ant-select-selection--multiple {
      height: auto;
    }
  }
  .ant-checkbox-wrapper {
    margin-top: 0 !important;
    margin-bottom: 15px;
  }
}

input.has-error {
  border-color: $colorDanger;
  &:focus {
    border-color: $colorDanger;
  }
  &:hover {
    border-color: $colorDanger;
  }
}

.expertise_field_section {
  ul {
    display: inline;
  }
  ul li {
    display: inline-block;
    margin-right: 5px;
  }
}

.form-error {
  font-size: 13px;
  margin-top: 0;
  line-height: 1;
  color: $colorDanger;
}

.sidebar-filter-web {
  &.fixed {
    position: fixed;
    top: -200px;
    z-index: 99;
  }
}

@media all and (max-width: 768px) {
  .applicant_profile_view {
    .ant-drawer-content-wrapper {
      width: 80% !important;
    }
  }
}

@media all and (max-width: 500px) {
  .applicant_profile_view {
    .ant-drawer-content-wrapper {
      width: 94% !important;
    }
  }
}

.update-email {
  h2 {
    font-size: 18px;
    font-weight: bold;
    color: #444;
  }
}

.side_bar_profile {
  position: relative;
  //.shortlist-btn{
  //  position: absolute;
  //  width: 100%;
  //  bottom: ;
  //}
  .resume-info .icon {
    display: none;
  }
  .my-resume {
    padding: 0;
  }

  .profile-intro {
    background-color: #eee;
    padding: 15px;
    display: flex;
    margin-bottom: 20px;
    .image-box {
      margin-right: 15px;
      height: 100px;
      width: 100px;
      img {
        width: 100%;
      }
    }
    .intro-box {
      h3 {
        font-size: 20px;
        font-weight: bold;
        color: #666;
        + p {
          font-size: 13px;
        }
      }
    }
  }
}

.jobs-listing {
  .job-meta {
    li i {
      margin-right: 10px;
      font-size: 12px;
    }
  }
  h2 {
    color: white;
    font-size: 35px;
    font-weight: bold;
    & + p {
      padding-top: 15px;
      max-width: 700px;
      font-size: 15px;
      margin: auto;
      color: #ccc;
    }
  }
}

.update-wrong-email {
  padding: 40px 15px 0 15px;
  .correct_email {
    color: #c3c3c3;
    font-size: 12px;
  }
}

.same_address {
  float: left;
  line-height: 1;
  .ant-checkbox-wrapper {
    margin-top: 0;
    span {
      font-size: 12px;
      color: #999;
    }
  }
  .ant-checkbox-inner {
    height: 13px;
    width: 13px;
  }
}

.job-list-container {
  padding: 30px 0;
  min-height: 400px;
  h1 {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    color: #888;
    & + p {
      text-align: center;
      max-width: 500px;
      font-size: 13px;
      //margin-top: 20px;
      // margin: 3px auto;
    }
  }

  .job-item-list {
    padding: 20px 0;
    position: relative;
    .filter_box {
      padding: 15px;
      width: 100%;
      min-height: 100px;
      background-color: white;
      border-radius: 5px;
      box-shadow: 3px 3px 10px 1px rgba(0, 0, 0, 0.05);
      margin-bottom: 20px;
      h3 {
        border-bottom: 1px solid #e3e3e3;
        padding: 0 0 10px 0;
        color: #777;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
    .filters-selected {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .pagi {
        p {
          color: #555;
        }
      }
      .filter_se {
      }
    }
    .job_list_view_container {
      min-height: 100%;
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      .ant-spin-dot-item {
        background: $colorPrimary !important;
      }
      .ant-spin-text {
        color: $colorPrimary;
        font-weight: bold;
      }

      .pagination_job_list {
        padding-bottom: 20px;
        margin-top: auto;
        //align-self: flex-end;
        justify-content: flex-end;
      }
      .job_list_view {
        display: flex;
        flex-direction: column;
        .list_view {
          background-color: white;
          margin-bottom: 20px;
          // height: 140px;
          width: 100%;
          border-radius: 8px;
          box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.05);
          border: 1px solid #e0e0e0;
          padding: 10px;
          display: flex;
          &.alert_view {
            border-top: 10px solid $colorDanger !important;
          }
          .job_logo {
            align-self: center;
            flex: 1;
            //display: flex;
            img {
              width: 100%;
              display: block;
              margin: auto;
            }
          }
          .job_details {
            flex: 5;
            padding: 5px 15px;
            h2 {
              font-size: 16px;
              margin-bottom: 10px;
              font-weight: bold;
              color: #777;
            }
            p {
              font-size: 14px;
              margin-bottom: 5px;
              color: #999;
              i {
                font-size: 12px;
                color: #999;
                margin-right: 5px;
              }
            }
          }
          .job_action {
            padding-top: 20px;
            span {
              background-color: $colorPrimary;
              color: white;
              padding: 2px 10px;
              border-radius: 2px;
              font-size: 13px;
              margin-bottom: 10px;
              &.a_0 {
                background-color: #2db7f5;
              }
              &.a_2 {
                background-color: #fa8c16;
              }
              &.a_ {
                background-color: #999;
              }
            }
            flex: 1.5;
            display: flex;
            flex-direction: column;
            align-items: center;
            a.btn {
              border-radius: 5px;
              &.following {
                background-color: #f5f5f5;
                color: #999;
              }
              i {
                font-size: 14px;
                line-height: 20px;
                color: $colorPrimary;
              }
            }
          }
        }
      }
    }
  }
}

.feedback_container {
  ul.emoticons_rate {
    margin-top: 0;
    li {
      display: inline-block;
      padding: 0 10px;
      cursor: pointer;
      &:first-child {
        padding-left: 0;
      }
      a {
        &.selected {
          opacity: 0.5;
        }
        font-size: 38px;
        transition: all 0.4s;
        position: relative;
        &:hover,
        &.active {
          opacity: 1;
          top: -5px;
        }
      }
    }
  }
  .feed_back_form {
    button.ant-btn.ant-btn-primary {
      float: right;
    }
    h1 {
      font-size: 24px;
      color: $colorPrimary;
      margin-bottom: 30px;
      //border-bottom: 1px solid #ccc;
    }
    .form-group {
      margin-bottom: 30px;
      .hint {
        font-size: 14px;
        color: #999;
      }
    }
    label {
      font-size: 15px;
      color: #666;
      margin-bottom: 5px;
    }
  }
}

.feed_backs_handler {
  position: fixed;
  right: -40px;
  cursor: pointer;
  bottom: 30%;
  transform: rotate(-90deg);
  height: auto;
  background: #444;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: bold;
  padding: 10px 20px;
  z-index: 9;
}

.free_vacancies {
  sup {
    background-color: $colorPrimary;
    font-size: 12px;
    height: 17px;
    line-height: 17px;
    right: 0;
    padding: 0 7px;
    top: 25px;
  }
}

.following_btn {
  background-color: #f2f2f2 !important;
  color: #999 !important;
  i {
    font-size: 14px !important;
    line-height: 20px;
    color: $colorPrimary;
  }
}

.card-container {
  .card-header {
    padding: 5px 15px;
    //background-color: transparent;
    h4 {
      color: #888;
      font-weight: bold;
    }
  }
  .card-body {
    display: flex;
    .icon-avatar {
      //flex: 1;
      width: 30px;
    }
    .body-content {
      flex: 10;
      strong {
        font-weight: bold;
      }
      small {
        font-size: 12px;
        color: #888;
      }
      p {
        line-height: 1;
      }
    }
    .body-action {
      width: 140px;
    }
  }
  .verification_link {
    h2 {
      font-size: 16px;
      font-weight: bold;
      color: #777;
    }
    p {
      font-size: 13px;
      color: #999;
      strong {
        font-weight: bold;
      }
    }
  }
}

.rating-star {
  position: relative;
  //width: 100%;
  overflow: visible !important;
  .anticon-close {
    background-color: #999;
    border-radius: 50%;
    padding: 3px;
    color: white;
    font-size: 12px;
    position: absolute;
    right: 0;
    top: -10px;
    cursor: pointer;
  }
}

.unverified_email {
  h2 {
    font-size: 20px;
    font-weight: bold;
    color: $colorDanger;
  }
  p {
    font-size: 14px;
    margin-top: 15px;
    color: #666;
  }
  a {
    font-weight: bold;
  }
}

.resume_progress_bar {
  background-color: white;
  margin-bottom: 10px;
  border-radius: 3px;
  padding: 0;
  .ant-progress-bg {
    height: 6px !important;
    margin-top: 0 !important;
  }
  .ant-progress-inner {
    vertical-align: top !important;
  }
  .r_info_p_info {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
  }
  .r_info {
    max-width: 550px;
    padding-right: 20px;
  }
  .ant-progress-status-success .ant-progress-bg {
    background-color: $colorPrimary;
  }
  .ant-progress-status-exception .ant-progress-bg {
    background-color: $colorDanger;
  }
  .ant-switch .anticon {
    vertical-align: -0.125em;
  }
}

.social-icon-bg {
  .add_more {
    background-color: rgba(0, 0, 0, 0.2);
    i {
      line-height: 30px;
      text-align: center;
      display: inline-block;
      width: 30px;
    }
    height: 30px;
    padding-right: 10px;
    //width: 30px;
    border: 1px dashed #ccc;
    display: block;
    border-radius: 30px;
    font-size: 12px;
    color: white;
    text-decoration: none;
  }
}

.social_modal {
  .selected {
    border: 1px dashed $colorPrimaryActive;
    background-color: $colorPrimary;
    color: white;
  }
}

.auth-modal {
  //.ant-popover{
  //  z-index: 99999!important;
  //}
  .ant-modal-header {
    display: none;
  }
  .ant-modal-close {
    display: none;
  }
  &.auth-form {
    .ant-modal-close {
      display: block !important;
    }
  }
  .ant-modal-body {
    padding: 0 50px 50px 50px;
  }
  .ant-modal-footer {
    display: none;
  }
}

.user-image {
  overflow: hidden;
  position: relative;
  display: flex !important;
  margin: 0 auto 0 auto;
  .overlay-upload {
    top: 0;
    cursor: pointer;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  input.user-profile-upload {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.remove-icon-image {
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-top: 5px;
  color: white !important;
}

footer {
  padding: 96px 0 73px;
  margin: 0;
  width: 100%;
  float: left;
  background: #1d2731;
  //background: url('https://cdn.rojgarisanjal.com/rojal/defaults/assets/footer-bg.jpg') left top no-repeat;
  background-size: cover;
}

footer .footer-section {
  border-bottom: 1px solid #374655;
  margin: 0 0 30px;
  padding: 0 0 30px;
  width: 100%;
}

.footer-section .single-secton h3 {
  color: #00b365;
  font-size: 22px;
  font-weight: 700;
  margin: 0 0 20px;
  padding: 0;
}

.footer-section .form .form-group {
  float: left;
  width: 70%;
}

.footer-section .form input.form-control {
  background: #ffffff none repeat scroll 0 0;
  border: medium none;
  border-radius: 30px 0 0 30px;
  color: #666666;
  font-size: 15px;
  font-weight: 400;
  height: 50px;
  line-height: 50px;
  margin: 0;
  padding: 0 0 0 6%;
  text-align: left;
  width: 100%;
}

.footer-section .form input.bttn {
  background: #00b365 none repeat scroll 0 0;
  border: medium none;
  border-radius: 0 30px 30px 0;
  color: #ffffff;
  cursor: pointer;
  float: left;
  font-family: "Rajdhani", sans-serif;
  font-size: 14px;
  font-weight: 700;
  height: 50px;
  line-height: 50px;
  margin: 0 0 0 -2px;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  width: 30%;
}

.single-section-two img {
  margin: 0 0 20px;
}

.single-section-two p {
  color: #ffffff;
  font-family: "Rajdhani", sans-serif;
  font-size: 17px;
  font-weight: 300;
}

.single-section-two.white h3 {
  color: #fff;
}

.single-section-two h3 {
  color: #00b365;
  font-size: 22px;
  font-weight: 700;
  margin: 10px 0 40px;
  padding: 0;
}

.single-section-two ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.single-section-two ul li:before {
  background: #00b365 none repeat scroll 0 0;
  content: "";
  height: 12px;
  left: 2px;
  position: absolute;
  top: 7px;
  width: 13px;
}

.single-section-two ul li {
  list-style: outside none none;
  padding: 0 0 8px 25px;
  position: relative;
}

.single-section-two ul li a {
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
}

.single-section-two .form input.form-control {
  background: #ffffff none repeat scroll 0 0;
  border: medium none;
  border-radius: 30px;
  color: #666666;
  font-size: 15px;
  font-weight: 400;
  height: 45px;
  line-height: 45px;
  margin: 0;
  padding: 0 0 0 6%;
  text-align: left;
  width: 100%;
}

.not_found {
  max-width: 300px;
  margin: 50px auto 0 auto;
  h3 {
    font-weight: bold;
    color: #999;
  }
  p {
    font-size: 12px;
    margin-top: 10px;
    line-height: 1.5;
    color: #999;
  }
  img {
    margin-top: 20px;
    opacity: 0.7;
    height: auto;
  }
}

.terms-page {
  h2 {
    font-size: 25px;
    font-weight: bold;
    color: #666;
    margin-top: 40px;
  }
  text-align: left !important;
  ul {
    padding-left: 60px;
    list-style: upper-roman;
  }
}

.about-para {
  margin: auto;
  line-height: 25px;
  color: #666;
  max-width: 800px;
  text-align: center;
  p {
    strong {
      font-weight: bold;
    }
    margin: 20px 0;
  }
}

.single-section-two .form input.form-control.message {
  width: 70%;
  float: left;
  border-radius: 30px 0 0 30px;
}

.single-section-two .form input.bttn {
  background: #00b365 none repeat scroll 0 0;
  border: medium none;
  border-radius: 0 30px 30px 0;
  color: #ffffff;
  cursor: pointer;
  float: left;
  font-family: "Rajdhani", sans-serif;
  font-size: 16px;
  font-weight: 700;
  height: 45px;
  line-height: 45px;
  margin: 0 0 0 -2px;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  width: 30%;
}

/**** social icons ****/
.footer-section ul {
  display: inline-block;
  margin: 6px 0 0;
}

.footer-section ul li:first-child {
  padding: 0;
}

footer .footer-section {
  border-bottom: 1px solid #374655;
  margin: 0 0 30px;
  padding: 0 0 30px;
  width: 100%;
}

.footer-section .single-secton {
  margin: 0 0 20px;
}

.footer-section ul li {
  color: #ffffff;
  display: inline-block;
  margin: 0;
  padding: 0 0 0 20px;
  transition: all 0.3s ease-in-out 0s;
}

.footer-section ul li a {
  border-radius: 50%;
  display: block;
  height: 35px;
  line-height: 33px;
  padding: 0;
  text-align: center;
  width: 35px;
  border: 1px solid #00b365;
}

.footer-section ul li a i {
  font-size: 14px;
  color: #fff;
}

.footer-section p {
  color: #fff;
  font-size: 20px;
  display: inline-block;
  margin: 10px 0 0;
}

.footer-section p span {
  font-weight: 600;
}

.footer-section ul li.facebook a:hover,
.footer-section ul li.facebook a:active {
  background: #4e71a8;
  border-color: #4e71a8;
}

.footer-section ul li.twitter a:hover,
.footer-section ul li.twitter a:focus {
  background: #1cb7eb;
  border-color: #1cb7eb;
}

.footer-section ul li.linkedin a:hover,
.footer-section ul li.linkedin a:focus {
  background: #0a80ac;
  border-color: #0a80ac;
}

.footer-section ul li.instagram a:hover,
.footer-section ul li.instagram a:focus {
  background: #dd164c;
  border-color: #dd164c;
}

.footer-section ul li.youtube a:hover,
.footer-section ul li.youtube a:focus {
  background: #ca3737;
  border-color: #ca3737;
}

/* list Category */

.list-category {
  text-align: center;
}

.category-list-top {
  border-radius: 4px;
  overflow: hidden;
}

.category-list-top li {
  float: left;
  width: 33%;
  font-size: 16px;
  background-color: #fff;
  border-right: 1px solid #e6e3e6;
  border-bottom: 1px solid #e6e3e6;
}

.category-list-top.category-list-bottom li {
  border-bottom: 0;
}

.category-list-top li:last-child {
  border-right: 0;
}

.category-list-top li a {
  color: #666;
  display: block;
  padding: 15px 15px;
  float: left;
}

.category-list-top span {
  display: block;
}

.category-list-top .category-title {
  color: #00b365;
}

.category-list-top .icon {
  margin-bottom: 5px;
  min-height: 40px;
}

.category-list-top .icon img {
  display: inline-block;
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -ms-filter: grayscale(1);
  -o-filter: grayscale(1);
  filter: grayscale(1);
}

.category-list-top li:hover .icon img {
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -ms-filter: grayscale(0);
  -o-filter: grayscale(0);
  filter: grayscale(0);
}

/**** Copyrights Wrapper ****/
.copyright-wrapper {
  background: #00b365;
  float: left;
  padding: 15px 0;
  width: 100%;
}

.copyright-wrapper p {
  color: #fff;
  font-size: 15px;
  margin: 0;
  text-align: center;
  font-weight: 300;
}

.tr-steps {
  background-image: url("https://rojal.s3.ap-south-1.amazonaws.com/defaults/assets/step-bg.jpg");
  position: relative;
  z-index: 1;
}

.tr-steps .step {
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  padding: 20px 10px 10px;
}

.tr-steps .step-image {
  min-height: 75px;
  margin-bottom: 15px;
  padding-left: 40%;
}

.tr-steps h1 {
  font-size: 20px;
  color: #00aeef;
  margin: 0;
  text-align: center;
}

.tr-steps h2 {
  font-size: 18px;
  text-transform: uppercase;
  margin: 10px 0 30px;
  text-align: center;
}

.error-svg {
  max-width: 500px;
  padding: 20px;
  margin: auto;
}

.error-page {
  padding: 100px;
}

@media all and (max-width: 425px) {
  .error-page {
    padding: 50px 10px 0 10px;
  }
}

////////////////NPROGRESS//////////////////////
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: $colorPrimary;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px $colorPrimary, 0 0 5px $colorPrimary;
  opacity: 1;
  z-index: 9999;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 9999;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: $colorPrimary;
  border-left-color: $colorPrimary;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.footer-sheet-app {
  position: fixed;
  //border: 100px;
  z-index: 9;
  box-shadow: -3px -3px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  left: 0;
  display: flex;
  justify-content: space-between;
  //border-top: 5px solid $colorPrimary;
  bottom: 0;
  color: black;
  width: 100%;
  height: 60px;
  background-color: white;
  .nav-item {
    text-align: center;
    color: #888;

    i {
      font-size: 22px;
    }
    h3 {
      font-size: 12px;
      font-weight: 600;
      color: #888;
    }
    &.active {
      color: $colorPrimary;
      h3 {
        color: $colorPrimary;
      }
    }
  }
  //z-index: 111;
}

////////////////NPROGRESS//////////////////////

.sticky-content-test {
  padding-top: 50px;
}

.kitty_class {
  margin-bottom: 30px;
}

.sidebar {
  will-change: min-height;
}

.sidebar__inner {
  transform: translate(0, 0); /* For browsers don't support translate3d. */
  transform: translate3d(0, 0, 0);
  will-change: position, transform;
}

a.btn.disabled,
fieldset:disabled a.btn {
  cursor: not-allowed !important;
  pointer-events: none;
}

.user_cv_des {
  background-color: white;
  overflow: hidden;
  position: relative;
  .anticon {
    vertical-align: -0.7em;
    svg {
      color: $colorPrimaryActive;
    }
  }

  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 15px;
  }

  h1 {
    font-size: 25px;
    color: white;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .users-intro-section {
    height: 250px;
    width: 100%;

    .skew_container {
      height: inherit;
      background-color: #00b365;
      min-height: 310px;
      width: 120%;
      left: -100px;
      transform: rotate(2deg);
      position: relative;
      top: -25px;

      .content_info {
        color: white;
        padding-top: 100px;
        padding-left: 200px;
        transform: rotate(-2deg);
        .updated {
          float: right;
          margin-right: 80px;
          font-size: 11px;
          margin-top: -60px;
        }

        .image_and_title .title {
          width: 60%;
          float: left;
          margin-top: 0;
        }

        .image_and_title .user_image {
          width: 15%;
          float: left;
          margin-bottom: 65px;
        }
        .user_image img {
          margin-top: -30px;
          border: 3px solid white;
          border-radius: 360%;
          width: 100%;
        }
      }
    }
  }

  .users_details {
    position: relative;
    z-index: 99;
    width: 78%;
    border: 3px solid #daffee;
    margin: -70px auto 20px auto;
    box-shadow: 0 0 0.5em 2px rgba(0, 0, 0, 0.09);
    & > p {
      font-size: 14px;
      margin-bottom: 15px;
      color: #999;
    }
    p {
      font-size: 15px;
    }
    .u_d_content {
      background: #daffee;
      padding: 25px 15px 5px 15px;
      min-height: 200px;
    }
    p.title {
      font-size: 12px;
      color: #999;
    }
    table {
      border: none;
      margin-top: -20px;
    }
    td {
      padding-bottom: 23px;
      color: #555;
      border: none;
    }
    td a {
      color: #555;
      text-decoration: none;
    }
    td i {
      color: #00b365;
    }

    .u_o_content {
      min-height: 100px;
      padding: 20px 15px 0 15px;
      background-color: #f0fff8;
    }
  }

  .career_objective {
    padding: 30px 0;
    width: 75%;
    margin: auto;
    text-align: center;
    min-height: 150px;

    h2 {
      margin-bottom: 20px;
      padding-bottom: 15px;
      color: #999;
      border-bottom: 1px dashed #ccc;
    }
    p {
      line-height: 26px;
      color: #333;
      font-size: 15px;
    }
  }

  .languages_section {
    padding: 10px 0 0 0;
    .lang-box {
      width: 25%;
      float: left;
    }
    h4 {
      color: #999;
    }
    i {
      font-size: 14px;
      color: #999;
    }
    i.fa.fa-star {
      color: #00b365;
    }
  }

  .special-skills {
    text-align: left;
    background-size: 20%;
    background-position: right 90px;
    background-repeat: no-repeat;
    min-height: 250px;
    ul {
      width: 90%;
      li {
        margin-bottom: 10px;
      }
    }
    p {
      line-height: 18px;
    }
  }

  .academic_qualifications {
    text-align: left;
    .academic-box {
      .anticon {
        vertical-align: -0.7em;
        svg {
          color: white;
        }
      }
      -webkit-print-color-adjust: exact;
      -webkit-filter: opacity(1);
      //min-height: 100px;
      padding: 14px 15px 10px 0;
      background: white;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      display: flex;
      margin-bottom: 20px;
      .icon i {
        font-size: 12px;
        margin-right: 8px;
        background-color: #00b365;
        padding: 4px 4px 4px 4px;
        color: white;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      .content {
        h1 {
          font-size: 17px;
          color: #666;
        }
        p {
          color: #666;
          font-size: 13px;
          margin-top: 8px;
          line-height: 0;
          /*color:red;*/
        }
        h1 + p {
          font-size: 12px;
          margin-top: 0;
          color: #888;
          line-height: 2.5;
        }
      }
    }
  }
  .cv_credit {
    position: absolute;
    bottom: 10px;
    right: 15px;
    font-size: 11px;
    color: #c3c3c3;
  }
}

///////////////////////////////

.user_cv_des_modified {
  background-color: white;
  overflow: hidden;
  position: relative;
  .anticon {
    vertical-align: -0.7em;
    svg {
      color: $colorPrimaryActive;
    }
  }

  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 15px;
  }

  h1 {
    font-size: 25px;
    color: white;
    margin-bottom: 0;
    font-weight: bold;
  }

  .users-intro-section {
    height: 250px;
    width: 100%;

    .skew_container {
      height: inherit;
      background-color: #00b365;
      min-height: 310px;
      width: 120%;
      left: -100px;
      transform: rotate(2deg);
      position: relative;
      top: -25px;

      .content_info {
        color: white;
        padding-top: 90px;
        padding-left: 180px;
        transform: rotate(-2deg);
        .updated {
          float: right;
          margin-right: 80px;
          font-size: 11px;
          margin-top: -60px;
        }

        .image_and_title .title {
          width: 60%;
          float: left;
          margin-top: 0;
        }

        .image_and_title .user_image {
          width: 15%;
          float: left;
          margin-bottom: 40px;
        }
        .user_image img {
          margin-top: -30px;
          border: 3px solid white;
          border-radius: 360%;
          width: 100%;
        }
      }
    }
  }

  .users_details {
    position: relative;
    z-index: 99;
    width: 78%;
    border: 3px solid #daffee;
    margin: -70px auto 20px auto;
    box-shadow: 0 0 0.5em 2px rgba(0, 0, 0, 0.09);
    & > p {
      font-size: 14px;
      margin-bottom: 15px;
      color: #999;
    }
    p {
      font-size: 15px;
    }
    .u_d_content {
      background: #daffee;
      padding: 25px 15px 5px 15px;
      min-height: 200px;
    }
    p.title {
      font-size: 12px;
      color: #999;
    }
    table {
      border: none;
      margin-top: -20px;
    }
    td {
      padding-bottom: 23px;
      color: #555;
      border: none;
    }
    td a {
      color: #555;
      text-decoration: none;
    }
    td i {
      color: #00b365;
    }

    .u_o_content {
      min-height: 100px;
      padding: 20px 15px 0 15px;
      background-color: #f0fff8;
    }
  }

  .career_objective {
    padding: 30px 0;
    width: 75%;
    margin: auto;
    text-align: center;
    min-height: 150px;

    h2 {
      margin-bottom: 20px;
      padding-bottom: 15px;
      color: #999;
      border-bottom: 1px dashed #ccc;
    }
    p {
      line-height: 26px;
      color: #333;
      font-size: 15px;
    }
  }

  .languages_section {
    padding: 10px 0 0 0;
    .lang-box {
      width: 25%;
      float: left;
    }
    h4 {
      color: #999;
    }
    i {
      font-size: 14px;
      color: #999;
    }
    i.fa.fa-star {
      color: #00b365;
    }
  }

  .special-skills {
    text-align: left;
    background-size: 20%;
    background-position: right 90px;
    background-repeat: no-repeat;
    min-height: 250px;
    ul {
      width: 90%;
      li {
        margin-bottom: 10px;
      }
    }
    p {
      line-height: 18px;
    }
  }

  .academic_qualifications {
    text-align: left;
    .academic-box {
      .anticon {
        vertical-align: -0.7em;
        svg {
          color: white;
        }
      }
      -webkit-print-color-adjust: exact;
      -webkit-filter: opacity(1);
      //min-height: 100px;
      padding: 14px 15px 10px 0;
      background: white;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      display: flex;
      margin-bottom: 20px;
      .icon i {
        font-size: 12px;
        margin-right: 8px;
        background-color: #00b365;
        padding: 4px 4px 4px 4px;
        color: white;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      .content {
        h1 {
          font-size: 17px;
          color: #666;
        }
        p {
          color: #666;
          font-size: 13px;
          margin-top: 8px;
          line-height: 0;
          /*color:red;*/
        }
        h1 + p {
          font-size: 12px;
          margin-top: 0;
          color: #888;
          line-height: 2.5;
        }
      }
    }
  }
  .cv_credit {
    position: absolute;
    bottom: 10px;
    right: 15px;
    font-size: 11px;
    color: #c3c3c3;
  }
}

///////////////////////////////////

.download_status {
  background-color: rgba(1, 179, 101, 0.3);
  color: white;
}

.btn-square {
  border-radius: 2px !important;
}

.app_general_modal {
  .ant-modal-header {
    display: none;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-close-x {
    display: none;
  }
}

.resume_modal {
  .cv_main_container {
    scroll-behavior: smooth;
  }
  .cv_container {
    &.is_sample_cv {
      height: 500px;
      width: 790px;
      scroll-behavior: smooth;
      padding: 10px 20px;
      background-color: #666;
    }

    background-color: #666;
    //width: 100%;
    .ant-spin-text {
      color: white;
      font-size: 18px;
      text-shadow: none !important;
    }
    .ant-spin-dot-item {
      background-color: white !important;
    }
  }
  .footer_button {
    padding-top: 20px;
    border-top: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .resume_header_control {
    display: flex;
    .cv_section {
      flex: 1;
      padding: 5px 5px 10px 0;
      margin-right: 8px;
      cursor: pointer;
      display: flex;

      .icon-den {
        border: 2px solid rgba(1, 179, 101, 0.8);
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        i {
          color: $colorPrimary;
        }
      }
      .section_title {
        margin-left: 10px;
        h5 {
          font-weight: bold;
          color: #555;
        }
      }

      &.active {
        border-bottom: 4px solid $colorPrimaryActive;

        .icon-den {
          background-color: rgba(1, 179, 101, 0.05);
        }
      }
      &.disabled {
        border-bottom: 4px solid rgba(0, 0, 0, 0.1);

        .icon-den {
          border: 2px solid rgba(0, 0, 0, 0.07);
          box-shadow: none;
          i {
            color: #ccc;
          }

          background-color: rgba(0, 0, 0, 0.05);
        }
        .section_title {
          h5 {
            color: #ccc;
          }
        }
      }
    }
  }
}

.class_favorite_add {
  position: absolute !important;
  right: 5%;
  bottom: 5%;
  display: inline-block;
}

.btn-outlined {
  border: 1px solid $colorPrimary;
  color: $colorPrimary !important;
  text-transform: uppercase;
  padding: 10px 15px !important;
  &:hover {
    background: $colorPrimary !important;
    color: $colorPrimary !important;
  }
}

.site-map-container {
  .category-section {
    columns: 3 auto;
    margin: 10px 0 0;
    padding-bottom: 25px;
  }
  h1 {
    text-align: left;
    border-bottom: 1px solid #ccc;
  }
  h2 {
    font-size: 18px;
    font-weight: 500;
  }
  ul {
    li {
      a {
        font-size: 14px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.bttn {
  &[disabled] {
    background-color: #ccc !important;
  }
}

.blog_grid_box_container {
  [class^="col"] {
    display: flex;
    flex-direction: column;
  }
  [class^="col"] div {
    flex-grow: 1;
  }
}

.blog_grid_box {
  border: 1px solid #ddd;
  background-color: white;
  transition: 0.3s;
  margin-bottom: 15px;
  padding: 0 0 5px 0;
  figure {
    height: 200px;
    overflow: hidden;
  }
  h1 {
    text-align: left;
    color: #333;
    font-size: 18px !important;
  }
  p {
    text-align: left !important;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:hover {
    -webkit-transform: scale(1.03);
    -ms-transform: scale(1.03);
    transform: scale(1.03);
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  }
  .blog_grid_content {
    padding: 20px 20px 20px 20px;
  }
  h1 {
    font-size: 20px;
    line-height: 1.1;
    margin-bottom: 5px;
    font-weight: normal;
    + p {
      margin-bottom: 0.8rem;
      font-size: 14px;
    }
  }
  p {
    color: #a0a0a0;
    strong {
      color: #555;
      font-weight: 300;
    }
  }
  a.view_more {
    border-radius: 0;
    margin-top: 30px;
  }
  @media screen and (min-width: 768px) {
    width: 100%;
  }
}
.blog-detail-image-box {
  background-color: white;
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1);
  padding: 0 20px 20px 20px;
  margin-bottom: 40px;
  img {
    width: 100%;
  }

  p {
    margin-top: 30px;
    font-size: 16px;
    text-align: justify;
    color: #7c7c7c;
    line-height: 1.8;
    // padding-bottom: 20px;
  }
  h1 {
    font-size: 34px;
    color: #23a84c;
    padding-top: 20px;
    text-align: left;
    margin-bottom: 15px;
  }
  ol,
  ul {
    padding-left: 20px;
  }
  h1,
  h2,
  h3 {
    color: $colorPrimary;
    &:not(:first-child) {
      margin-top: 20px !important;
    }
  }
  .authered_by {
    p {
      text-align: right;
    }
  }
}

.company_profile_image_gen{
  height:100%;
  width:100%;
  display: flex;
  flex:1 1;
  justify-content: center;
  align-items: center;
  font-size: 80px;
  font-weight: bold;
  color:white;
}


@media (max-width: 425px) {
  .company_profile_image_gen:not(.grid_pig) {
    width:100%!important;
    height:250px!important;
    margin-bottom: 15px;
  }
  .company_profile_image_gen.grid_pig {
    height:65px!important;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .company_profile_image_gen:not(.grid_pig) {
    width:100%!important;
    height:80px!important;
    margin-bottom: 0;
  }
}