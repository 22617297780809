.faq_page {
  h1 {
    font-size: 35px;
    margin-top: 30px;
    margin-bottom: 20px;
    color: $textDartColor;
  }
  .ant-input-search-enter-button + .ant-input-group-addon, .ant-input-search-enter-button input + .ant-input-group-addon {
    padding: 7px;
  }
  .ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button, .ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
    border-radius: 6px;
  }
  .ant-input-lg {
    font-weight: bold;
    height: 54px;
  }
  .ant-input-group {
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.2);
  }
  .ant-input-prefix {
    i {
      font-size: 20px;
    }
  }
  .ant-input {
    border: none;
  }
  .ant-btn-primary {
    padding: 0 20px;
  }
  .ant-input-group-addon {
    background-color: white;
  }
  p {
    opacity: 0.4;
    font-weight: bold;
    font-size: 18px;
    color: $textDartColor;
  }
  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 40px;
  }
  .ant-input-group {
    border-radius: 9px;
    overflow: hidden;
  }
}

.faq-category {
  margin-top: 40px;
  margin-bottom: 40px;
  ul {
    display: flex;
    justify-content: center;
    li {
      margin: 0 15px;
      a {
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
        i {
          font-size: 30px;
          color: $textDartColor;
          opacity: 0.3;
        }
        p {
          font-weight: bold;
          opacity: 0.8;
          margin-top: 10px;
          font-size: 16px;
          color: $textDartColor !important;
        }
        height: 120px;
        width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 5px;
        &.active, &:hover {
          i {
            color: $colorPrimary;
            opacity: 1;
          }
          box-shadow: 0 1px 15px rgba(0, 0, 0, 0.17);
          border: 1px solid $colorPrimary;
        }
      }
      display: inline-block;
    }
  }
}

.faq_content {
  p {
    opacity: 0.7;
    font-weight: normal;
    margin-bottom: 15px;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding-left: 0;
  }
  .ant-collapse {
    background-color: transparent;
    border: none;
  }
  .ant-collapse-content {
    border-top: none;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-size: 18px;
    font-weight: bold;
    color: $textDartColor;
    opacity: 0.8;
    //border-bottom: 1px solid #ccc;
    padding: 25px 0;
  }
  .ant-collapse-content {
    background-color: transparent;
    padding-bottom: 40px;
  }
  //.ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header{
  //  border-radius: 0;
  //}
}