.ant-input:hover,
.ant-input:focus,
.ant-select-selection:hover,
.ant-select-selection:focus,
.ant-calendar-picker:hover {
  border-color: $colorPrimary;
  //color: $colorPrimary;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: $colorPrimary;
}

.btn.btn-primary {
  background-color: $colorPrimary !important;
  color: white;
}

.btn.btn-primary:hover {
  border-color: $colorPrimary !important;
}

.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: $colorPrimary;
}

//.btn:focus {
//  border-color: transparent;
//}
//
//.btn:hover{
//  border: 1px solid $colorPrimary;
//}

.ant-tabs-tab {
  padding-left: 5px !important;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: $colorPrimary !important;
  border-color: $colorPrimary !important;
}

.ant-tabs-ink-bar {
  background-color: $colorPrimary;
}

.ant-btn:not([disabled]):hover,
.ant-btn:not([disabled]):focus {
  color: $colorPrimary;
  border-color: $colorPrimary;
}

.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fff !important;
  background-color: #ff7875 !important;
  border-color: #ff7875 !important;
}

.ant-tabs-nav .ant-tabs-tab:hover:not(.ant-tabs-tab-disabled) {
  color: $colorPrimary;
}

.btn.btn-default {
  background-color: #e3e3e3;
  color: #666;
}

.btn.btn-default:hover {
  color: #666 !important;
}

.work-history span {
  display: inline-block !important;
}

.breadcrumb-social {
  margin-top: 5px !important;
  margin-bottom: 10px !important;
  &.small {
    margin-top: 0;
  }
  li a i {
    text-align: center;
    &.small {
      height: 25px;
      width: 25px;
      line-height: 25px;
      margin-right: 12px;
    }
  }
}

.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  margin-bottom: 5px;
}

.ant-btn-primary {
  background-color: $colorPrimary;
  border-color: $colorPrimary;
}

.ant-switch {
  .anticon {
    vertical-align: 0.875em;
  }
}

.ant-switch-checked {
  background-color: $colorPrimary;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: rgb(255, 255, 255);
  background-color: $colorPrimaryActive;
  border-color: $colorPrimaryActive;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: $colorPrimary !important;
  border-color: $colorPrimary !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $colorPrimary !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $colorPrimary !important;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: $colorPrimary;
  border-color: $colorPrimary;
}

.ant-radio-button-wrapper:hover {
  color: $colorPrimary;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: $colorPrimary;
  border-color: $colorPrimary;
}

.ant-radio-inner::after {
  background-color: $colorPrimary;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: $colorPrimary;
}

.uploader-modal .ant-modal-header {
  display: none;
}

.ant-popover {
  z-index: 9999 !important;
}

.ant-list-item.active {
  background-color: rgba(0, 0, 0, 0.02);
}

.ant-modal-mask,
.ant-modal-wrap {
  z-index: 9999;
}

.ant-btn.ant-btn-primary:not([disabled]):hover,
.ant-btn.ant-btn-primary:not([disabled]):focus {
  color: white;
}

.ant-btn-primary:active,
.ant-btn-primary.active {
  background-color: $colorPrimaryActive;
  border-color: $colorPrimaryActive;
}

.ant-drawer {
  z-index: 9999;
}

.ant-select-dropdown {
  z-index: 9999;
}

.ant-tag {
  .anticon {
    vertical-align: 0;
  }
}

.ant-calendar-picker-container {
  z-index: 9999;
}

.ant-spin-dot-item {
  background-color: $colorPrimary !important;
}

.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 0 !important;
}

.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 100px;
  height: 100px;
}

.ant-skeleton-content {
  .ant-skeleton-title {
    margin-top: 15px !important;
    height: 22px !important;
  }
  .ant-skeleton-paragraph {
    margin-top: 20px !important;
    li {
      height: 10px !important;
      margin-top: 10px !important;
    }
  }
}

.anticon {
  vertical-align: 0.1em;
}

.ant-popover {
  z-index: 3;
}

.ant-tooltip {
  z-index: 9999;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: $colorPrimaryActive !important;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  background-color: $colorPrimaryActive !important;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  border-color: $colorPrimaryActive;
}

.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  //margin-top: 10px;
  //margin-bottom: 10px;
}

.ant-progress-success-bg,
.ant-progress-bg {
  background-color: $colorPrimary !important;
}

.edit-resume .height_auto {
  .ant-select-selection {
    height: auto;
  }
}

.ant-back-top {
  z-index: 999;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  //background-color: $colorPrimary;
  @include primary-color-rgba(0.1);
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: $colorPrimary;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  @include primary-color-rgba(0.1);
}

.ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  margin-right: 0;
}

.ant-pagination-item-active,
.ant-pagination-item:focus,
.ant-pagination-item:hover,
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: $colorPrimary;
  color: $colorPrimary !important;
}

.ant-popover {
  width: 400px;
}
.profile-tabs > li {
  width: 100%;
}
.blog_add_info {
  i {
    vertical-align: 0.1em !important;
  }
}
