.contact_info {
  margin-top: 30px;
  text-align: center;
  i {
    font-size: 40px;
    color: $colorPrimary;
  }
  p {
    margin-top: 20px;
    color: #0a0a0a;
  }
  .info_box_c {
    cursor: pointer;
  }
  a {
    color: #0a0a0a;
  }
}

.contact_form {
  padding: 60px 50px 40px 50px;
  border-radius: 10px;
  background-color: white;
  margin: 40px 0;

  label {
    margin-bottom: 10px;
    color: #0a0a0a;
  }
  .form-group {
    margin-bottom: 30px;
  }
  .ant-input-affix-wrapper .ant-input:not(:last-child), .ant-input-affix-wrapper .ant-input:not(:first-child), form .ant-mentions, form textarea.ant-input {
    background-color: #F8F8FA;
    font-weight: bold;
    font-size: 18px;
    border: none;
    &:focus {
      border: 2px solid $colorPrimary;
    }
  }
  .ant-input-lg {
    height: 55px;
  }
}

@media all and (max-width: 575px) {
  .contact_form {
    padding: 20px;
    margin-top: 20px;
  }
  .contact_info {
    margin-top: 10px;
    i {
      font-size: 24px;
    }
    p {
      margin-top: 13px;
      font-size: 12px;
    }
  }
}

.contact_success {
  i {
    display: block;
    color: $colorPrimary;
    text-align: center;
    font-size: 100px;
  }
  margin: auto;
  //width: 200px;
  h1 {
    margin-top: 30px;
    font-size: 50px;
  }
  p {
    line-height: 1.5;
    font-size: 18px !important;
    text-align: center;
  }
  button{
    margin-top: 20px;
    position: relative;
    transform: translate(-50%, -50%);
    left: 50%;
  }
}